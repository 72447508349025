@import './../styles/index.scss';

.loading {
  width: 100%;
  height: 100vh;
  background-color: $c-header;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  width: 250px;
}

.cookie {
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}
