@import './../styles/index.scss';

.policyPage {
  padding-top: 160px;

  @media screen and (max-width: 480px) {
    padding-top: 100px;
  }
}

.descriptionContainer {
  margin-bottom: 40px;
  padding: 20px;

  @media screen and (max-width: 480px) {
    margin-bottom: 10px;
    padding: 15px;
  }
}

.title {
  margin-bottom: 20px;
  @media screen and (max-width: 480px) {
    margin-bottom: 10px;
    font-size: 20px;
  }
}

.description {
  font-size: 18px;
  margin-bottom: 10px;
  line-height: 28px;
  @media screen and (max-width: 480px) {
    margin-bottom: 7px;
    font-size: 16px;
  }
}
