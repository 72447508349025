@import './../../styles/index.scss';

.nav {
  display: flex;
  gap: 30px;
  align-items: center;

  @media screen and (max-width: $desktop) {
    display: none;
  }
}

.link {
  text-decoration: none;
  color: $c-white;
  padding: 20px;
  font-family: $ff-main;
  font-size: 16px;
  transition: $t-normal;
}

.link:hover {
  color: $c-link-hover;
}

.link:active {
  color: $c-nav-active;
}
