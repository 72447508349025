* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 0;
  overflow-x: hidden
}

img {
  // min-width: 100%;
  // min-height: 100%;
  width: 100%;
  height: 100%;
  display: block;
  width: 100%;
  height: auto;
  object-fit: cover;
}

body::-webkit-scrollbar {
    cursor: pointer;
  width: 7px;
}

body::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.9);

}


body::-webkit-scrollbar-thumb {
  background-color: rgba(255, 0, 0, 0.5);
  border-radius: 10px;
  box-shadow: 0px 4px 20px #57627333;

  &:hover {
  background-color: rgba(255, 0, 0, 0.9);

  }
}

body {
  position: relative;
  font-family: $ff-main;
  font-weight: $fw-regular;
  font-size: 16px;
}

a {
  text-decoration: none;
  color: black;
  cursor: pointer;
}

svg {
  width: 100%;
  height: 100%;
}

.container {
  height: 100%; // допомагає, коли колір бекграунду не покриває весь контент внизу
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  // border: 1px solid red;
}

.containerServices {
  height: 100%;
  width: 100%;
  max-width: 1640px;
  margin: 0 auto;
}
