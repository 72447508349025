@import './../../styles/index.scss';

.service {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
  margin-top: 50px;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 100px 40px;

  &:first-of-type {
    margin-top: 50px;
  }

  @media screen and (min-width: 1280px) and (max-width: 1600px) {
    gap: 50px;
    padding-top: 80px;
    padding-bottom: 80px;
  }

  @media screen and (min-width: 950px) and (max-width: 1280px) {
    // flex-direction: column;
    gap: 25px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 60px;
    padding-bottom: 60px;
  }

  @media screen and (min-width: $phone) and (max-width: 950px) {
    flex-direction: column;
    gap: 25px;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  @media screen and (max-width: $phone) {
    flex-direction: column;
    gap: 15px;
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 12px;
    padding-right: 12px;
    margin-top: 25px;
    margin-bottom: 25px;
  }
}

.swiperSlide {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.swiperSlide img {
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: center;
}

.serviceReverse {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
  gap: 100px;
  margin-top: 50px;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 100px 40px;

  @media screen and (min-width: 1280px) and (max-width: 1600px) {
    gap: 50px;
    padding-top: 80px;
    padding-bottom: 80px;
  }

  @media screen and (min-width: 950px) and (max-width: 1280px) {
    // flex-direction: column;
    gap: 25px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 60px;
    padding-bottom: 60px;
  }

  @media screen and (min-width: $phone) and (max-width: 950px) {
    flex-direction: column;
    gap: 25px;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 60px;
    padding-bottom: 60px;
  }

  @media screen and (max-width: $phone) {
    flex-direction: column;
    gap: 15px;
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 12px;
    padding-right: 12px;
    margin-top: 25px;
    margin-bottom: 25px;
  }
}

.images {
  width: 100%;
  height: auto;

  @media screen and (min-width: 1330px) and (max-width: 1550px) {
    // max-width: 600px;
    height: auto;
  }

  @media screen and (min-width: 1200px) and (max-width: 1330px) {
    // max-width: 500px;
    height: auto;
  }

  @media screen and (min-width: 1100px) and (max-width: 1200px) {
    // max-width: 450px;
    height: auto;
  }

  @media screen and (min-width: 1024px) and (max-width: 1100px) {
    // max-width: 400px;
    height: auto;
  }

  @media screen and (min-width: 950px) and (max-width: 1024px) {
    // max-width: 450px;
    height: auto;
  }

  @media screen and (max-width: 950px) {
    // max-width: 700px;
    height: auto;
  }
}

.noDescImages {
  width: 100%;
  height: auto;

  @media screen and (max-width: 1350px) {
  }
}

.noDesc {
  background-color: rgba(255, 255, 255, 0.1);
  flex-direction: column;
  margin-bottom: 50px;
  margin-top: 50px;
  align-items: center;
  justify-content: center;
  padding: 100px 40px;

  @media screen and (min-width: 1280px) and (max-width: 1600px) {
    gap: 50px;
    padding-top: 80px;
    padding-bottom: 80px;
  }

  @media screen and (min-width: 950px) and (max-width: 1280px) {
    // flex-direction: column;
    gap: 25px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 60px;
    padding-bottom: 60px;
  }

  @media screen and (min-width: $phone) and (max-width: 950px) {
    flex-direction: column;
    gap: 25px;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 60px;
    padding-bottom: 60px;
  }

  @media screen and (max-width: $phone) {
    flex-direction: column;
    gap: 15px;
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 12px;
    padding-right: 12px;
    margin-top: 25px;
    margin-bottom: 25px;
  }
}

.infosContainer {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;

  @media screen and (max-width: 1280px) {
    max-width: 800px;
  }
}

.noDescInfos {
  width: 100%;
  text-align: center;
}

.numberContainer {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 15px;
  margin-bottom: 40px;

  @media screen and (max-width: 1200px) {
    margin-bottom: 20px;
  }
}

.circle {
  background-color: $c-link-hover;
  // width: 60px;
  flex-basis: 60px;
  flex-shrink: 0;
  flex-grow: 0;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  @media screen and (max-width: 1600px) {
    flex-basis: 45px;
    height: 45px;
  }
}

.number {
  font-size: 18px;
  color: $c-white;
  font-weight: 700;
}

.title {

  font-size: 24px;
  color: $c-white;

  @media screen and (min-width: 950px) and (max-width: 1650px) {
    font-size: 20px;
  }

  @media screen and (min-width: $phone) and (max-width: 950px) {
    font-size: 22px;
  }

  @media screen and (max-width: $phone) {
    font-size: 20px;
  }
}

.description {
  // margin-left: 75px;
  font-size: 18px;
  color: $c-white;
  line-height: 28px;

  @media screen and (min-width: 1400px) and (max-width: 1650px) {
    font-size: 16px;
  }

  @media screen and (min-width: 950px) and (max-width: 1400px) {
    font-size: 14px;
  }

  @media screen and (min-width: $phone) and (max-width: 950px) {
    font-size: 16px;
  }

  @media screen and (max-width: $phone) {
    font-size: 14px;
  }
}

.line {
  height: 1px;
  opacity: 0.5;
  width: 100%;
  background-color: $c-white;

  &:last-child {
    display: none;
  }
}

.image {
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: center;
  
}
