@import './../../styles/index.scss';

.button {

  margin: 0 auto;
  padding: 10px 30px;
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: transparent;
  color: $c-white;
  font-family: 'Raleway', sans-serif;
  font-weight: 600;
  border: 1px solid $c-white;
  border-radius: 30px;
  // box-shadow: $box-shadow;
  transition: $t-normal;
  cursor: pointer;
  margin-bottom: 10px;
    display: none;

  @media screen and (max-width: 1024px) {
    display: block;
  }
}

.button:hover {
  box-shadow: none;
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  color: white;
  transform: scale(1.1);
}

.button:active {
  background-color: $c-link-active;
}

.content {
  pointer-events: none;
  margin: 0 auto;
  // width: 100%;
  max-height: 0;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 20px;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: $t-normal;

  @media screen and (max-width: $tablet) {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: $t-normal;
  }
}

.contentActive {
  margin: 0 auto;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 20px;
  overflow: visible;
  max-height: 300px;
  transition: $t-normal;

  @media screen and (min-width: $phone) and (max-width: $desktop) {
    overflow: visible;
    max-height: 430px;
    transition: $t-normal;
  }

  @media screen and (max-width: $phone) {
    overflow: visible;
    max-height: 740px;
    // width: 100%;
    margin: 0 auto;
    transition: $t-normal;
  }
}

.links {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
  column-gap: 40px;
  row-gap: 10px;

  @media screen and (min-width: $phone) and (max-width: $tablet) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: $phone) {
    grid-template-columns: 1fr;
  }
}

.link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 4px;

  &:hover {
    .text {
      color: $c-link-hover;
    }

    .iconArrow {
      transform: translateX(10px);
    }
  }
}

.line {
  margin-top: 2px;
  margin-bottom: 2px;
  width: 100%;
  height: 1px;
  background-color: #fff;
}

.text {
  font-size: 14px;
  transition: $t-normal;
  color: $c-white;
}
