@import './../../../styles/index.scss';

.animationContainer {
  width: 200px;
  height: 200px;
  // border: 1px solid red;

  @media screen and (max-width: 1500px) {
    width: 150px;
    height: 150px;
  }
}

.oldAdsContainer {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 1fr 1fr;
  margin-bottom: 100px;

  @media screen and (max-width: 1500px) {
    grid-template-columns: 1fr;
  }
}

.oldAdContainer {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 170px 1fr;
  align-items: center;
  gap: 50px;

  @media screen and (min-width: 1500px) and (max-width: 1600px) {
    max-width: 100%;
  }

  @media screen and (min-width: $tablet) and (max-width: 1500px) {
    max-width: 1000px;
    gap: 20px;
  }

  @media screen and (max-width: $tablet) {
    display: flex;
    flex-direction: column;
    gap: 0;
    max-width: 450px;
  }
}

.info {
  text-align: left;
  font-size: 18px;
  color: $c-white;
  line-height: 28px;

  @media screen and (max-width: 1600px) {
    font-size: 16px;
  }
}
