@import './../styles/index.scss';

.servicePageWrapper {
  width: 100%;
  height: 100%;
  background: linear-gradient(
      to top,
      rgba(0, 0, 0, 0.6),
      rgba(0, 0, 0, 0.9),
      rgba(0, 0, 0, 0.9)
    ),
    url('./../assets/images/services-bg4.jpg');
  background-position: center;
  background-size: contain;
  animation: position 120s linear infinite;

  @media screen and (min-width: $tablet) and (max-width: $desktop) {
    animation: position 170s linear infinite;
  }

  @media screen and (min-width: $phone) and (max-width: $tablet) {
    animation: position 200s linear infinite;
  }

  @media screen and (max-width: $phone) {
    animation: position 400s linear infinite;
    background: rgba(0, 0, 0, 0.9), url('./../assets/images/services-bg4.jpg');
  }
}

@keyframes position {
  100% {
    background-position: 0 50px;
  }
}

.servicesWrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: inline-block;
  // background-color: $c-header;
  padding-top: 100px;
  display: block;
  padding-left: 280px;
  transition: $t-normal;
  @media screen and (min-width: 1700px) and (max-width: 1900px) {
    padding-left: 280px;
  }

  @media screen and (min-width: 1500px) and (max-width: 1700px) {
    padding-left: 260px;
  }

  @media screen and (min-width: $desktop) and (max-width: 1500px) {
    padding-left: 250px;
  }

  @media screen and (max-width: $desktop) {
    padding-left: 0;
  }
}

.servicesWrapperOff {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: inline-block;
  // background-color: $c-header;
  padding-top: 100px;
  display: block;
  padding-left: 0px;
  transition: $t-normal;

  @media screen and (min-width: 1500px) and (max-width: 1700px) {
    padding-left: 30px;
  }

  @media screen and (min-width: 1024px) and (max-width: 1500px) {
    padding-left: 20px;
  }
}

// .footerWrapper {
//   background-color: rgba(1, 1, 1, 0.8);
// }

// .imgContainer {
//   mix-blend-mode: multiply;
// }

// ! Catalog
// todo
.list {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  bottom: 0;
  padding: 100px 80px 50px 30px;
  background-color: $c-bg;
  width: 100%;
  height: 100%;
  max-width: 370px;
  display: grid;
  transition: $t-normal;
  overflow-y: auto;

  @media screen and (min-width: 1700px) and (max-width: 1900px) {
    max-width: 300px;
  }

  @media screen and (min-width: 1500px) and (max-width: 1700px) {
    max-width: 260px;
    padding: 85px 45px 50px 15px;
  }

  @media screen and (min-width: 1024px) and (max-width: 1500px) {
    max-width: 250px;
    padding: 85px 45px 50px 15px;
  }

  @media screen and (max-width: $desktop) {
    display: none;
  }
}

::-webkit-scrollbar {
  width: 0;
}
// todo
.listClose {
  left: -330px;
  background-color: transparent;
  overflow-y: hidden;

  @media screen and (min-width: 1700px) and (max-width: 1900px) {
    left: -260px;
  }

  @media screen and (min-width: 1500px) and (max-width: 1700px) {
    left: -230px;
  }

  @media screen and (min-width: $desktop) and (max-width: 1500px) {
    left: -221px;
  }
}

.content {
  display: flex;
}

.links {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 15px;
}

.service {
  width: 100%;
  height: 100%;
  position: relative;
}
// todo
.text {
  color: $c-white;
  transition: $t-normal;

  @media screen and (min-width: $desktop) and (max-width: 1700px) {
    font-size: 14px;
  }
}
// todo
.iconArrow {
  position: absolute;
  margin: auto;
  top: 50%;
  bottom: 50%;
  right: -20px;
  transition: $t-normal;

  @media screen and (min-width: $desktop) and (max-width: 1700px) {
    right: -5px;
  }
}

.link {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 90% 10%;
  padding-top: 2px;
  padding-bottom: 2px;
  align-items: center;
  gap: 20px;

  &:hover {
    .text {
      color: $c-link-hover;
    }

    .iconArrow {
      right: -25px;

      @media screen and (min-width: $desktop) and (max-width: 1700px) {
        right: -10px;
      }
    }
  }
}

.line {
  width: 105%;
  height: 1px;
  background-color: #fff;
  margin-top: 7px;
}

.buttonContainer {
  // display: none;
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;
  width: 40px;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    .icon {
      fill: $c-red;
      padding-right: 5px;
    }
  }
  // todo

  @media screen and (min-width: $desktop) and (max-width: 1700px) {
    width: 30px;
  }
}

// ! Content

.contentContainer {
  padding: 30px;

  @media screen and (min-width: $tablet) and (max-width: 1200px) {
    padding: 20px;
  }

  @media screen and (min-width: $phone) and (max-width: $tablet) {
  }

  @media screen and (max-width: $phone) {
    padding: 8px;
  }
}

.subtitle {
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 50px;
  color: $c-red;
  text-transform: uppercase;

  @media screen and (min-width: $phone) and (max-width: $desktop) {
    font-size: 18px;
    margin-bottom: 50px;
  }

  @media screen and (max-width: $phone) {
    font-size: 16px;
    margin-bottom: 50px;
  }
}

.arrow {
  margin: 0 auto;
  display: block;
  width: 100px;
  height: 100px;

  @media screen and (max-width: $desktop) {
    width: 60px;
    height: 60px;
    margin-bottom: 10px;
  }
}

.catalogueLine {
  display: none;
}

.catalogueLineActive {
  display: block;
  height: 1px;
  opacity: 0.5;
  width: 100%;
  background-color: $c-white;
  margin-top: 50px;
  margin-bottom: 50px;
}
