@import './../../styles/index.scss';

.service {
  height: 100%;
  max-height: 500px;
  width: 100%;
  // max-width: 800px;

  width: 100%;
  overflow: hidden;
  margin-bottom: 70px;
  

  @media screen and (min-width: 1330px) and (max-width: 1550px) {
    // max-width: 600px;
    height: auto;
    margin-bottom: 30px;
  }

  @media screen and (min-width: 1200px) and (max-width: 1330px) {
    // max-width: 500px;
    height: auto;
    margin-bottom: 30px;
  }

  @media screen and (min-width: 1100px) and (max-width: 1200px) {
    // max-width: 450px;
    height: auto;
    margin-bottom: 20px;
  }

  @media screen and (min-width: 1024px) and (max-width: 1100px) {
    // max-width: 400px;
    height: auto;
    margin-bottom: 20px;
  }

  @media screen and (min-width: 950px) and (max-width: 1024px) {
    // max-width: 450px;
    height: auto;
    margin-bottom: 10px;
  }

  @media screen and (max-width: 950px) {
    // max-width: 700px;
    height: auto;
    margin-bottom: 10px;
  }

  // @media screen and (min-width: 1100px) and (max-width: 1200px) {
  //       width: 700px;
  //   height: auto;
  // }

  //   @media screen and (min-width: 1000px) and (max-width: 1100px) {
  //       width: 600px;
  //   height: auto;
  // }
}

.noDesc {
  width: 100%;
  height: auto;
  max-height: 600px;
  margin-bottom: 70px;
}

.swiper-button-next,
.swiper-button-prev {
  position: absolute;
  color: $c-link-hover !important;
  padding: 30px 26px;
  border-radius: 5px;
}

.swiper-pagination-bullet-active {
  background-color: #000 !important;
}

.service .swiper-wrapper {
  max-height: 400px;
}

.noDesc .swiper-wrapper {
  max-height: 600px;
}
