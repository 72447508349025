@import './../../styles/index.scss';

.headerWrapper {
  position: fixed;
  z-index: 11;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 90px;
  background-color: rgba(7, 7, 7, 0.5);
  animation: appear 1s ease-out;
  transition: $t-normal;
  cursor: pointer;

  &:hover {
    // height: 110px;

    .logoContainer {
      transform: scale(1.1);
    }
  }

  @media screen and (max-width: $phone) {
    height: 60px;
  }
}

@keyframes appear {
  0% {
    filter: blur(30px);
  }
  100% {
    filter: none;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px 20px;
}

.logoContainer {
  margin-top: 10px;
  width: 100px;
  transition: $t-normal;

  @media screen and (max-width: $phone) {
    width: 80px;
  }
}

.logoContainer img {
  width: 100px;

  @media screen and (max-width: $phone) {
    width: 80px;
  }
}

.navVertical {
  position: fixed;
  display: flex;
  flex-direction: column;
  gap: 50px;
  z-index: 20;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: center;
  align-items: center;
  background-color: $c-bg;
  width: 100%;
  height: 100%;
  padding: 30px;
}

.navClose {
  display: none;
}

.burgerContainer {
  width: 30px;
  height: 30px;
  @media screen and (min-width: $desktop) {
    display: none;
  }
}

.social {
  @media screen and (max-width: $desktop) {
    display: none;
  }
}

.cross {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 30px;
  height: 30px;
}
