@import './../../styles/index.scss'; 

.openImagesWrapper {
  position: fixed;
  z-index: 20;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.9);
  width: 100%;
  height: 100%;
}

.openImagesContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.openImageContainer {
  width: auto;
  height: 75vh;
  cursor: move;
  display: flex;
  align-items: center;
}

.openImageContainer img {
  object-fit: contain;
  overflow: visible;
  width: auto;
  max-width: 100%;
  height: 100%;
}

// ! Controls
.controlsContainer {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  bottom: 10px;
}

.controlButton {
  background-color: $c-link-hover;
  color: $c-white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: opacity 0.3s ease;
  transition: $t-normal;

  @media screen and (max-width: $phone) {
    padding: 0;
    width: 40px;
    height: 40px;
  }
}

.controlButton:hover {
  background-color: $c-link-active;
}
.controlButton:active {
  background-color: rgb(23, 23, 23);
}

.crossContainer {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.big {
  @media screen and (max-width: $phone) {
    display: none;
  }
}

.small {
  width: 20px;
  height: 20px;
  @media screen and (min-width: $phone + 1) {
    display: none;
  }
}