@import './../../styles/index.scss';

.nav {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.link {
  text-decoration: none;
  color: $c-white;
  padding: 15px 20px;
  // font-family: "Rubik", sans-serif;
  font-family: $ff-main;
  font-size: 16px;
  transition: $t-normal;
}

.link:hover {
  transform: scaleX(1.1);
  color: $c-link-hover;
}

.link:active {
  color: $c-nav-active;
}
