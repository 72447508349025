@import '../../styles/index.scss';

.link {
  transform: rotate(90deg);
  border-radius: 50%;
  transition: all 0.2s ease-in-out;
  width: 100%;
  height: 100%;
  max-width: 55px;
  max-height: 55px;
  position: fixed;
  right: 40px;
  bottom: 60px;
  z-index: 11;
  padding: 10px;
  background-color: $c-link-hover;
  opacity: 0.5;
  border: 1px solid transparent;

  @media screen and (min-width: $phone) and (max-width: $tablet) {
    right: 20px;
    bottom: 20px;
  }

  @media screen and (max-width: $phone) {
    max-width: 45px;
    max-height: 45px;
    right: 20px;
    bottom: 20px;
  }

  &:hover {
    opacity: 1;
  }

  &:active {
    background-color: $c-link-active;
    opacity: 0.5;
  }
}
