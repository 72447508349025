@import './../styles/index.scss';

.passportPageWrapper {
  width: 100%;
  height: 100%;
  background: url('./../assets/images/services-bg4.jpg');
  background-position: center;
  background-size: cover;
  animation: position 80s linear infinite;
}

@keyframes position {
  100% {
    background-position: 0 100px;
  }
}

.bg {
  background-color: $c-header;
  width: 100%;
  height: 100%;
}

.passportPage {
  width: 100%;
  padding-bottom: 100px;
  padding-top: 100px;
  padding-left: 15px;
  padding-right: 15px;

      @media screen and (max-width: $phone) {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.title {
  text-align: center;
  font-size: 52px;
  color: $c-white;
  margin-top: 100px;
  margin-bottom: 30px;
  text-transform: uppercase;
  transform: scale(1, 1.2);

  @media screen and (min-width: $phone) and (max-width: $desktop) {
    font-size: 42px;
  }

  @media screen and (max-width: $phone) {
    font-size: 32px;
  }
}

.subtitle {
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 50px;
  color: $c-red;
  text-transform: uppercase;

  @media screen and (min-width: $phone) and (max-width: $desktop) {
    font-size: 18px;
    margin-bottom: 50px;
  }

  @media screen and (max-width: $phone) {
    font-size: 16px;
    margin-bottom: 50px;
  }
}

.arrow {
  margin: 0 auto;
  display: block;
  width: 100px;
  height: 100px;
  margin-bottom: 70px;

  @media screen and (max-width: $desktop) {
    width: 60px;
    height: 60px;
    margin-bottom: 50px;
  }
}

.passportContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 50px;
  margin-bottom: 100px;

  @media screen and (min-width: $phone) and (max-width: $tablet) {
    padding: 30px;
    flex-direction: column;
    margin-bottom: 50px;
  }

    @media screen and (max-width: $phone) {
    padding: 20px;
    flex-direction: column;
    margin-bottom: 50px;
  }
}

.descriptionContainer {
  width: 100%;
  max-width: 600px;
}

.description,
.additional {
  color: $c-white;
  line-height: 28px;
  font-size: 18px;

  @media screen and (min-width: $phone) and (max-width: $desktop) {
    font-size: 16px;
  }

  @media screen and (max-width: $phone) {
    font-size: 14px;
  }
}

.description:first-child {
  margin-bottom: 70px;

  @media screen and (min-width: $phone) and (max-width: $tablet) {
    margin-bottom: 50px;
  }

    @media screen and (max-width: $phone) {
    margin-bottom: 30px;
  }
}

.additional span {
  font-size: 20px;
  font-weight: $fw-bold;
  border-bottom: 2px solid red;
}

.description span {
  font-size: 20px;
  font-weight: $fw-bold;
  border-bottom: 2px solid red;
}

.imageContainer {
  width: 100%;
  max-width: 700px;
  box-shadow: 20px 20px 20px 0px rgba(0, 0, 0, 0.5);
}

.priceContainer {
  width: 100%;
  max-width: 500px;
  text-align: center;
  color: $c-white;
  font-size: 18px;
  padding: 15px 10px;
  backdrop-filter: blur(20px);
  background-color: rgba(255, 255, 255, 0.2);

  @media screen and (min-width: $tablet) and (max-width: $desktop) {
    font-size: 16px;
  }

  @media screen and (max-width: $tablet) {
    padding: 10px 5px;
    font-size: 14px;
    margin: 0 auto;
  }
}

.additionalFunction {
  background-color: rgba(255, 255, 255, 0.1);
  padding: 50px;

  @media screen and (min-width: $phone) and (max-width: $tablet) {
    padding: 30px;
  }

  @media screen and (max-width: $phone) {
    padding: 20px;
  }
}

// ! Additional Function

.titleSecond {
  text-align: center;
  font-size: 38px;
  color: $c-white;
  margin-top: 30px;
  margin-bottom: 30px;
  text-transform: uppercase;
  transform: scale(1, 1.2);

  @media screen and (min-width: $phone) and (max-width: $desktop) {
    font-size: 32px;
  }

  @media screen and (max-width: $phone) {
    font-size: 22px;
  }
}

.subtitleSecond {
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  color: $c-red;
  margin-bottom: 50px;
  text-transform: uppercase;

  @media screen and (min-width: $phone) and (max-width: $tablet) {
    font-size: 18px;
    margin-bottom: 50px;
  }

  @media screen and (max-width: $phone) {
    font-size: 16px;
    margin-bottom: 50px;
  }
}

.circleContainer {
  display: grid;
  align-items: center;
  grid-template-columns: 40px 1fr;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  gap: 20px;
  margin-bottom: 30px;

  @media screen and (max-width: $tablet) {
    grid-template-columns: 1fr;
    grid-template-rows: 40px 1fr;
    margin-bottom: 60px;
    gap: 10px;
    text-align: center;
  }
}

.circle {
  background-color: rgba(255, 0, 0, 0.6);
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: #fff;
  font-weight: 700;

    @media screen and (max-width: $tablet) {
    justify-self: center;
  }
}

.textContainer {
  margin: 0 auto;
  width: 100%;
  max-width: 1100px;
  display: flex;
  gap: 50px;
  align-items: center;
  justify-content: center;
  margin-bottom: 100px;

  @media screen and (max-width: $tablet) {
    flex-direction: column;
    gap: 20px;
  }
}

.animationContainer {
  width: 100%;
  max-width: 400px;
  height: 100%;
}

.serviceTitle {
  margin: 0 auto;
  width: fit-content;
  text-align: center;
  font-size: 24px;
  color: $c-white;
  margin-top: 30px;
  margin-bottom: 40px;
  text-transform: uppercase;
  border-bottom: 2px solid red;

  @media screen and (max-width: $phone) {
    font-size: 20px;
  }
}
