@import './../../styles/index.scss';

.buttons {
  display: flex;
  gap: 4px;
  justify-content: center;
  align-items: center;
}

.pageButton {
  background-color: $c-red;
  border: none;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $c-white;
  cursor: pointer;
  transition: $t-normal;

  &:hover {
    background-color: $c-header;
  }
}