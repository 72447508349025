@import './../../styles/index.scss';

.icon {
  width: 100%;
  height: 100%;
  fill: $c-white;
  transition: $t-normal;
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
  }
}

.icon-star {
  fill: $c-link-hover;
}

.icon-arrow-right {
  width: 20px;
  height: 20px;
  fill: $c-red;

  @media screen and (max-width: 1700px) {
    width: 15px;
    height: 15px;
  }
}

.icon-arrow-wide-left,
.icon-wide-arrow {
  fill: $c-link-hover;

  &:hover {
    transform: none;
  }
}

.icon-cross {
  fill: $c-link-hover;
}
