.openSlider {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  height: 600px;
  width: 50%;
  top: 50%;
  transform: translateY(-50%);
}
