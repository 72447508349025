@import './../../styles/index.scss';

.imagesContainer {
  background-color: rgba(255, 255, 255, 0.1);
  margin: 0 auto;
  width: 100%;
  max-width: 1850px;
  column-count: 4;
  padding: 30px;

  @media screen and (min-width: $phone) and (max-width: $desktop) {
    padding: 25px;
    column-count: 3;
  }

  @media screen and (min-width: $phone) and (max-width: $tablet) {
    padding: 25px;
    column-count: 2;
  }

  @media screen and (max-width: $phone) {
    padding: 25px;
    column-count: 1;
  }
}


.imgContainer {
  width: 100%;
  height: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 10px;
  padding-left: 10px;
  cursor: pointer;
  transition: $t-normal;

  @media screen and (max-width: $tablet) {
    padding: 5px;
  }
}

.imgContainer:hover {
  transform: scale(1.1);
}

.line {
  margin: 0 auto;
  margin-top: 15px;
  margin-bottom: 15px;
  height: 1px;
  opacity: 0.5;
  width: 80%;
  background-color: $c-white;
}
