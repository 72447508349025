@import './../styles/index.scss';

.portfolioPageWrapper {
  position: relative;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.9)),
    url('./../assets/images/services-bg4.jpg');
  background-position: center;
  background-size: cover;
  animation: position 70s linear infinite;
}

@keyframes position {
  100% {
    background-position: 0 100px;
  }
}

.portfolioWrapper {
  // background-color: $c-header;
  width: 100%;
  padding-bottom: 100px;
  padding-top: 100px;
}

.footerWrapper {
  background-color: rgba(255, 255, 255, 1);
}

.imagesContainer {
margin-top: 20px;
margin-bottom: 20px;
}

.openImagesContainer {
  position: fixed;
  z-index: 20;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.9);
  width: 100%;
  height: 100%;
  padding: 30px;

  @media screen and (min-width: $phone) and (max-width: $tablet) {
    padding: 15px;
  }

  @media screen and (max-width: $phone) {
    padding: 0;
  }
}

.openImageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: move;
  width: auto;
  max-width: fit-content;
  height: 80vh;

  @media screen and (max-width: $tablet) {
    height: 60vh;
  }
}

.openImageContainer img {
  object-fit: contain;
  overflow: visible;
  width: auto;
  max-width: 100%;
  height: 100%;
}

.arrow {
  margin: 0 auto;
  display: block;
  width: 100px;
  height: 100px;
  margin-bottom: 100px;

  @media screen and (max-width: $desktop) {
    width: 60px;
    height: 60px;
    margin-bottom: 50px;
  }
}


// ! Controls
.controlsContainer {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  bottom: 10px;
}

.controlButton {
  background-color: $c-link-hover;
  color: $c-white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: opacity 0.3s ease;
  transition: $t-normal;

  @media screen and (max-width: $phone) {
    padding: 0;
    width: 40px;
    height: 40px;
  }
}

.controlButton:hover {
  background-color: $c-link-active;
}
.controlButton:active {
  background-color: rgb(23, 23, 23);
}

.crossContainer {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.big {
  @media screen and (max-width: $phone) {
    display: none;
  }
}

.small {
  width: 20px;
  height: 20px;
  @media screen and (min-width: $phone + 1) {
    display: none;
  }
}
