@import './../../styles/index.scss';

.social {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;

  @media screen and (max-width: $phone) {
    justify-content: center;
  }
}

.number {
  color: $c-white;
}
