@import './../../styles/index.scss';

.footerWrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: inline-block;
  background: linear-gradient(to bottom, black, $c-header);
  padding-top: 100px;
  padding-bottom: 100px;
  display: block;

  @media screen and (max-width: $tablet) {
    padding-bottom: 10px;
  }
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: start;
  padding-left: 30px;
  padding-right: 30px;

  @media screen and (min-width: $tablet) and (max-width: $desktop) {
    justify-content: space-around;
    gap: 50px;
  }

  @media screen and (max-width: $tablet) {
    flex-direction: column-reverse;
    align-items: center;
    gap: 50px;
  }
}

.logoContainer {
  width: 100%;
  max-width: 300px;

  @media screen and (max-width: $desktop) {
    display: none;
  }
}

.footerTitle {
  font-family: $ff-title;
  color: $c-link-hover;
  font-weight: 800;
  text-align: center;
  font-size: 22px;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.menu {
  @media screen and (max-width: 570px) {
    display: none;
  }
}

.contactsContainer {
  width: 100%;
  max-width: 450px;
  color: $c-white;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.contacts {
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media screen and (max-width: $tablet) {
    margin-bottom: 50px;
  }
}

.numberContainer {
  display: flex;
  flex-direction: column;
}

.flexContainer {
  // display: flex;
  // gap: 40px;
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media screen and (max-width: $phone) {
    grid-template-columns: 1fr;
    grid-template-rows: 2fr;
    row-gap: 20px;
    text-align: center;
  }
}

.smallTitle {
  font-size: 18px;
}

// .social {
//   margin: 0 auto;
//   height: 50px;
// }

.navContainer {
  @media screen and (max-width: $tablet) {
    display: none;
  }
}

.policyLink {
  margin-top: 40px;

  color: $c-white;
  font-size: 18px;
  border-bottom: 1px solid $c-white;
  width: fit-content;
  transition: $t-normal;

  &:hover {
    color: $c-link-hover;
    border-color: $c-link-hover;
  }
}
