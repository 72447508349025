@import './../../styles/index.scss';

// ! Titles
.titleMain {
  font-weight: 700;
  font-family: $ff-main;
  font-size: 56px;
  letter-spacing: 1px;
  color: $c-white;
  margin-bottom: 40px;
  transition: $t-normal;
  text-align: center;

  @media screen and (min-width: $phone) and (max-width: $tablet) {
    font-size: 44px;
  }

  @media screen and (max-width: $phone) {
    font-size: 32px;
  }
}

.page {
  text-align: center;
  font-size: 52px;
  color: $c-white;
  margin-top: 100px;
  margin-bottom: 30px;

  @media screen and (min-width: $phone) and (max-width: $desktop) {
    font-size: 42px;
  }

  @media screen and (max-width: $phone) {
    font-size: 32px;
  }
}

.titleBig {
  padding-top: 50px;
  text-align: center;
  font-size: 38px;
  color: $c-white;
  margin-top: 30px;
  margin-bottom: 50px;

  @media screen and (min-width: $phone) and (max-width: $tablet) {
    font-size: 32px;
    margin-bottom: 40px;
  }

  @media screen and (max-width: $phone) {
    font-size: 26px;
    margin-bottom: 40px;
  }
}

.titleMiddle {
  text-align: center;
  font-size: 26px;
  color: $c-white;
  margin-top: 30px;
  margin-bottom: 50px;

  @media screen and (min-width: $phone) and (max-width: $desktop) {
    font-size: 32px;
  }

  @media screen and (max-width: $phone) {
    font-size: 22px;
  }
}


// ! Subtitles
.subtitleMain {
  line-height: 20px;
  font-weight: 500;
  font-family: $ff-main;
  font-size: 24px;
  color: $c-link-hover;
  letter-spacing: 1px;
  margin-bottom: 100px;
  transition: $t-normal;
  text-align: center;

  @media screen and (min-width: $phone) and (max-width: $tablet) {
    font-size: 18px;
    margin-bottom: 50px;
  }

  @media screen and (max-width: $phone) {
    font-size: 16px;
    margin-bottom: 50px;
  }
}


.subtitleSmall {
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 120px;
  color: $c-red;

  @media screen and (min-width: $tablet) and (max-width: $desktop) {
    margin-bottom: 60px;
  }

  @media screen and (min-width: $phone) and (max-width: $tablet) {
    font-size: 18px;
    margin-bottom: 60px;
  }

  @media screen and (max-width: $phone) {
    font-size: 16px;
    margin-bottom: 50px;
  }
}