// ? Fonts
// Font Families
$ff-main: 'Rubik', sans-serif;
$ff-title: 'Big Shoulders Display', sans-serif;

// Font sizes
$fs-title: 32px;
$fs-main-title: 44px;

// Font weights
$fw-light: 300;
$fw-regular: 400;
$fw-medium: 500;
$fw-semi-bold: 600;
$fw-bold: 700;

// ? Line height
$line-height: 26px;
$line-height-small: 18px;

// ? Colors
$c-header: rgba(1, 1, 1, 0.902);
$c-white: #fff;
// $c-red: rgb(223, 38, 38);
// $c-link-hover: rgba(255, 0, 0, 0.6);
$c-link-hover: #c60c30;
$c-link-active: #5b0101;
$c-red: #c60c30;

$c-link-bg: #d2d2d2;
$c-nav-active: #868686;
$c-bg: rgba(26, 26, 26, 255);

// ? Shadows
$box-shadow: 8px 8px rgba(255, 0, 0, 0.5);

// ? Transition
$t-normal: 0.35s cubic-bezier(0.4, 0, 0.2, 1);

// ? Media Queries: Breakpoints
$small: 320px;
$phone: 480px;
$tablet: 768px;
$desktop: 1024px;
$screen: 1400px;
$large: 1920px;
